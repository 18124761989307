import * as React from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
import CardContent from "@mui/material/CardContent";
import CoursePart from "../../components/CoursePart";

const part1 = [
  {
    title: "1.1",
    description: "Hei maailma!",
    link: "/course/python/11",
  },
  {
    title: "1.3",
    description: "Syntaksi",
    link: "/course/python/13",
  },
  {
    title: "1.2",
    description: "Käyttäjän syöttö",
    link: "/course/python/12",
  },
  {
    title: "1.3",
    description: "Kommentit",
    link: "/course/python/13",
  },
  {
    title: "1.4",
    description: "Laskentaa",
    link: "/course/python/14",
  },
  {
    title: "1.4",
    description: "Vertailu",
    link: "/course/python/22",
  },
];

const part2 = [
  {
    title: "2.1",
    description: "Muuttujat",
    link: "/course/python/21",
  },
  {
    title: "2.2",
    description: "Kokonaisluvut",
    link: "/course/python/22",
  },
  {
    title: "2.3",
    description: "Liukuluvut",
    link: "/course/python/23",
  },
  {
    title: "2.4",
    description: "Totuusarvo",
    link: "/course/python/24",
  },
  {
    title: "2.5",
    description: "Merkkijonot",
    link: "/course/python/25",
  },
  {
    title: "2.6",
    description: "Lista",
    link: "/course/python/26",
  },
];

const part3 = [
  {
    title: "3.1",
    description: "Jos",
    link: "/course/python/11",
  },
  {
    title: "3.2",
    description: "Ensimmäinen ohjelma",
    link: "/course/python/12",
  },
  {
    title: "3.3",
    link: "/course/python/13",
  },
  {
    title: "3.4",
    link: "/course/python/22",
  },
];

const part4 = [
  {
    title: "4.1",
    description: "while",
    link: "/course/python/41",
  },
  {
    title: "4.2",
    description: "for",
    link: "/course/python/42",
  },
  {
    title: "4.3",
    link: "/course/python/43",
  },
  {
    title: "4.4",
    link: "/course/python/44",
  },
];

const part5 = [
  {
    title: "4.1",
    description: "Funktion tekeminen",
    link: "/course/python/11",
  },
  {
    title: "4.2",
    description: "Argumentit funktioissa",
    link: "/course/python/12",
  },
  {
    title: "4.3",
    description: "Funktion palautus",
    link: "/course/python/13",
  },
  {
    title: "4.4",
    description: "Rekursio",
    link: "/course/python/22",
  },
];

const part6 = [
  {
    title: "5.1",
    description: "Matematiikka",
    link: "/course/python/11",
  },
  {
    title: "5.2",
    description: "Satunnaisuus",
    link: "/course/python/12",
  },
];

export default function Main() {
  return (
    <main>
      <Link
        to="/"
        style={{
          marginTop: "15px",
          marginBottom: "15px",
          fontSize: "1.4em",
          color: "currentColor",
          textDecoration: "none",
        }}
      >
        ← Takaisin kursseihin
      </Link>
      <h1 style={{ marginTop: "15px" }}>Python Ohjelmointi</h1>
      <Typography style={{ marginTop: "15px" }}>
        Opi Python, yksi maailman suosituimmista ohjelmointikielistä.
      </Typography>
      <h1 style={{ marginTop: "15px" }}>1. Alkeet</h1>
      <Grid container spacing={4}>
        {part1.map((post) => (
          <CoursePart key={post.title} post={post} />
        ))}
      </Grid>
      <h1 style={{ marginTop: "15px" }}>2. Tietotyypit</h1>
      <Grid container spacing={4}>
        {part2.map((post) => (
          <CoursePart key={post.title} post={post} />
        ))}
      </Grid>
      <h1 style={{ marginTop: "15px" }}>3. Ehtolauseita</h1>
      <Grid container spacing={4}>
        {part3.map((post) => (
          <CoursePart key={post.title} post={post} />
        ))}
      </Grid>
      <h1 style={{ marginTop: "15px" }}>4. Silmukat</h1>
      <Grid container spacing={4}>
        {part4.map((post) => (
          <CoursePart key={post.title} post={post} />
        ))}
      </Grid>
      <h1 style={{ marginTop: "15px" }}>5. Funktiot</h1>
      <Grid container spacing={4}>
        {part5.map((post) => (
          <CoursePart key={post.title} post={post} />
        ))}
      </Grid>
      <h1 style={{ marginTop: "15px" }}>6. Moduulit</h1>
      <Grid container spacing={4}>
        {part6.map((post) => (
          <CoursePart key={post.title} post={post} />
        ))}
      </Grid>
      <hr style={{ marginTop: "20px", marginBottom: "20px" }} />
      <Grid container justifyContent="center" sx={{ mb: 5 }}>
        <Grid item xs={12} md={12}>
          <Card sx={{ display: "flex", bgcolor: "#8379B0" }}>
            <CardContent sx={{ flex: 1 }}>
              <Typography component="h2" variant="h5" color="#fff">
                Kurssin edistyminen (52%)
              </Typography>
              <LinearProgress variant="determinate" sx={{ my: 1 }} value="52" />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </main>
  );
}
