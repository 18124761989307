import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Alert from "@mui/material/Alert";
import { Link, useHistory } from "react-router-dom";
import validator from "email-validator";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

const theme = createTheme({
  palette: {
    background: {
      default: "#35385F",
    },
    primary: {
      main: "#fff",
    },
  },
});

export default function SignUp(props) {
  const [alertMessage, setAlertMessage] = React.useState();

  const history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");
    if (!validator.validate(email)) {
      setAlertMessage("Virheellinen sähköposti.");
      return;
    }
    if (password.length < 6) {
      setAlertMessage("Salasanan täytyy olla vähintään 6 merkkiä pitkä.");
      return;
    }
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setAlertMessage("");
        const user = userCredential.user;
        props.setUser(user);
        history.push("/");
      })
      .catch(() => {
        setAlertMessage("Sähköposti on jo käytössä.");
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#8379B0" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" style={{ color: "#fff" }}>
            Luo käyttäjätili
          </Typography>
          {alertMessage && (
            <Alert
              variant="filled"
              severity="error"
              style={{ backgroundColor: "#8379B0", color: "#fff" }}
              sx={{ mt: 1 }}
            >
              {alertMessage}
            </Alert>
          )}
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Sähköposti"
                  name="email"
                  autoComplete="email"
                  sx={{ input: { color: '#fff' } }}
                  InputLabelProps={{
                    style: { color: "#fff" },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Salasana"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  sx={{ input: { color: '#fff' } }}
                  InputLabelProps={{
                    style: { color: "#fff" },
                  }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{ backgroundColor: "#8379B0", color: "#fff" }}
              sx={{ mt: 3, mb: 2 }}
            >
              Luo käyttäjätili
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link
                  to="/signin"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                  }}
                >
                  Kirjaudu sisään
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
