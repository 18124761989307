import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";

function FeaturedPost(props) {
  const { post } = props;

  return (
    <Grid item xs={12} md={6}>
      <Link
        to={post.link}
        style={{
          textDecoration: "none",
        }}
      >
        <Card sx={{ display: "flex", bgcolor: "#8379B0" }}>
          <CardContent sx={{ flex: 1 }}>
            <Typography component="h2" variant="h5" color="#fff">
              {post.title}
            </Typography>
          </CardContent>
          <CardMedia
            component="img"
            sx={{
              width: 160,
              height: 100,
              display: { xs: "none", sm: "block" },
            }}
            image={post.image}
            alt={post.text}
          />
        </Card>
      </Link>
    </Grid>
  );
}

FeaturedPost.propTypes = {
  post: PropTypes.shape({
    link: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default FeaturedPost;
