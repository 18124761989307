import * as React from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ListItemText from "@mui/material/ListItemText";
import LinearProgress from "@mui/material/LinearProgress";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import CertificationDownload from "../CertificationDownload";

// import Python from "../lessons/Python.json";
import Verkkosivut from "../lessons/Verkkosivut.json";
import Komentorivi from "../lessons/Komentorivi.json";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function UserProgress() {
  // const [PythonProgress, setPythonProgress] = React.useState(0);
  const [VerkkosivutProgress, setVerkkosivutProgress] = React.useState(0);
  const [KomentoriviProgress, setKomentoriviProgress] = React.useState(0);
  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const userId = firebase.auth().currentUser.uid;
        firebase
          .firestore()
          .collection("users")
          .doc(userId)
          .get()
          .then((doc) => {
            setVerkkosivutProgress(Object.keys(doc.data().verkkosivut).length);
          })
          .catch(() => {});
        firebase
          .firestore()
          .collection("users")
          .doc(userId)
          .get()
          .then((doc) => {
            setKomentoriviProgress(Object.keys(doc.data().komentorivi).length);
          })
          .catch(() => {});
      }
    });
  }, []);

  const courses = [
    // {
    //   name: "Python Ohjelmointi",
    //   progress: (PythonProgress / Object.keys(Python).length) * 100,
    // },
    {
      name: "Verkkosivujen Kehitys",
      progress: (VerkkosivutProgress / Object.keys(Verkkosivut).length) * 100,
    },
    {
      name: "Komentorivi Tutuksi",
      progress: (KomentoriviProgress / Object.keys(Komentorivi).length) * 100,
    },
  ];

  return (
    <React.Fragment>
      <List disablePadding>
        {courses.map((course) => (
          <ListItem key={course.name} sx={{ py: 1, px: 0 }}>
            <ListItemText primary={course.name} />
            {course.progress >= 99 && (
              <Button
                variant="outlined"
                style={{ color: "#8379B0", borderColor: "#8379B0" }}
                size="small"
                sx={{ mx: 1 }}
                onClick={() => CertificationDownload(course.name)}
              >
                Todistus
              </Button>
            )}
            <Box sx={{ width: "65%" }}>
              <LinearProgressWithLabel
                value={course.progress}
                color="secondary"
              />
            </Box>
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  );
}
