import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";

function Header(props) {
  const logout = () => {
    const auth = getAuth();
    signOut(auth)
      .then((userCredential) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <Toolbar sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          noWrap
          sx={{ flex: 1 }}
        >
          <Link to="/" className="header-link" style={{ color: "#fff" }}>
            sumi
          </Link>
        </Typography>
        {props.user ? (
          <div>
            <Link
              to="/profile"
              style={{
                textDecoration: "none",
              }}
            >
              <Button
                variant="outlined"
                style={{ color: "#8379B0", borderColor: "#8379B0" }}
                size="small"
                sx={{ mx: 1 }}
              >
                Profiili
              </Button>
            </Link>
            <Button
              variant="outlined"
              style={{ color: "#8379B0", borderColor: "#8379B0" }}
              size="small"
              sx={{ mx: 0 }}
              onClick={logout}
            >
              Kirjaudu ulos
            </Button>
          </div>
        ) : (
          <div>
            <Link
              to="/signin"
              style={{
                textDecoration: "none",
              }}
            >
              <Button
                variant="outlined"
                style={{ color: "#8379B0", borderColor: "#8379B0" }}
                size="small"
                sx={{ mx: 1 }}
              >
                Kirjaudu
              </Button>
            </Link>
            <Link
              to="/signup"
              style={{
                textDecoration: "none",
              }}
            >
              <Button
                variant="outlined"
                style={{ color: "#8379B0", borderColor: "#8379B0" }}
                size="small"
                sx={{ mx: 0 }}
              >
                Luo käyttäjä
              </Button>
            </Link>
          </div>
        )}
      </Toolbar>
    </React.Fragment>
  );
}

export default Header;
