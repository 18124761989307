import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CheckCircle from "@mui/icons-material/CheckCircle";

import { Link } from "react-router-dom";

function CoursePart(props) {
  const { post } = props;

  return (
    <Grid item xs={12} md={6}>
      <Link
        to={post.link}
        style={{
          textDecoration: "none",
        }}
      >
        <Card sx={{ display: "flex", bgcolor: "#8379B0" }}>
          <CardContent sx={{ flex: 1 }}>
            <Typography component="h2" variant="h5" color="#fff">
              {post.title}
            </Typography>
            <Typography sx={{ ml: 2 }} color="#eee">
              {post.description}
            </Typography>
          </CardContent>
          <CheckCircle
            sx={{ m: 3 }}
            style={{ color: props.done ? "#fff" : "transparent" }}
          />
        </Card>
      </Link>
    </Grid>
  );
}

CoursePart.propTypes = {
  post: PropTypes.shape({
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default CoursePart;
