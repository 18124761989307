import * as React from "react";
import Grid from "@mui/material/Grid";
import MainFeaturedPost from "../components/MainFeaturedPost";
import FeaturedPost from "../components/FeaturedPost";
import Footer from "../components/Footer";

//Course images
import mainImage from "../assets/01.jpg";

// import courseImage01 from "../assets/02.jpg";
import courseImage02 from "../assets/03.jpg";
import courseImage03 from "../assets/04.jpg";

const mainFeaturedPost = {
  title: "Ilmaista opetusta jokaiselle",
  description:
    "Tarjoamme maksuttomia verkkokursseja tietotekniikkaan liittyen. Kurssit eivät vaadi erityisiä esivaatimuksia, joten voit aloittaa ne vaikka et olisi aiemmin perehtynyt aiheeseen. Omaa edistymistäsi voi seurata helposti kurssin sivun alaosasta tai omasta profiilistasi, josta voit myös tallentaa kurssitodistuksen, kun olet suorittanut kurssin loppuun.",
  image: mainImage,
};

const courses = [
  // {
  //   title: "Python Ohjelmointi",
  //   link: "course/python",
  //   image: courseImage01,
  // },
  {
    title: "Verkkosivujen Kehitys",
    link: "course/verkkosivut",
    image: courseImage02,
  },
  {
    title: "Komentorivi Tutuksi",
    link: "course/komentorivi",
    image: courseImage03,
  },
];

export default function Main() {
  return (
    <main>
      <MainFeaturedPost post={mainFeaturedPost} />
      <h1 style={{ marginTop: "15px" }}>Kurssit</h1>
      <Grid container spacing={4}>
        {courses.map((post) => (
          <FeaturedPost key={post.title} post={post} />
        ))}
      </Grid>
      <Footer />
    </main>
  );
}
