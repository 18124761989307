import jsPDF from "jspdf";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export default function DownloadPDF(course) {
  const userId = firebase.auth().currentUser.uid;
  firebase
    .firestore()
    .collection("users")
    .doc(userId)
    .get()
    .then((doc) => {
      const name = doc.data().name;
      const unit = "px";
      const size = "A4";
      const orientation = "landscape";
      const pdf = new jsPDF(orientation, unit, size);
      pdf.setFontSize(17);
      pdf.setTextColor(1, 28, 39);
      pdf.setDrawColor(53, 56, 95);
      pdf.setLineWidth(18);
      pdf.roundedRect(
        20,
        20,
        pdf.internal.pageSize.width - 20 * 2,
        pdf.internal.pageSize.height - 20 * 2,
        10,
        10,
        "S"
      );
      pdf.setFontSize(16);
      pdf.setFont("Helvetica");
      pdf.text(
        `${course} - Sumi`,
        pdf.internal.pageSize.width / 2,
        100,
        "center"
      );
      pdf.setFontSize(22);
      pdf.text(
        "Kurssitodistus",
        pdf.internal.pageSize.width / 2,
        130,
        "center"
      );
      pdf.setFontSize(34);
      pdf.setFont("Helvetica", "Bold");
      pdf.text(
        name.toUpperCase(),
        pdf.internal.pageSize.width / 2,
        pdf.internal.pageSize.height / 2,
        "center"
      );
      pdf.setFont("Helvetica", "normal");
      pdf.setFontSize(16);
      pdf.text(
        `On suorittanut ${course} kurssin hyväksytysti.`,
        pdf.internal.pageSize.width / 2,
        280,
        "center"
      );
      pdf.setLineWidth(1);
      pdf.setDrawColor(205, 205, 205);
      pdf.line(
        pdf.internal.pageSize.width * 0.38,
        355,
        pdf.internal.pageSize.width * 0.62,
        355
      );
      pdf.setFont("Helvetica", "Bold");
      pdf.text("Eemeli Mark", pdf.internal.pageSize.width / 2, 370, "center");
      pdf.setFont("Helvetica", "normal");
      pdf.text(
        "Kurssin opettaja",
        pdf.internal.pageSize.width / 2,
        385,
        "center"
      );
      pdf.save(`kurssitodistus-${course}-${name}.pdf`);
    })
    .catch((error) => {
      console.log(error);
    });
}
