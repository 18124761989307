import * as React from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import CardContent from "@mui/material/CardContent";
import CoursePart from "../../components/CoursePart";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import CertificationDownload from "../../CertificationDownload";

import Komentorivi from "../../lessons/Komentorivi.json";

const part1 = [
  {
    title: "1.1",
    description: "Mikä on komentorivi",
    link: "/course/komentorivi/11",
  },
  {
    title: "1.2",
    description: "Ensimmäinen komento",
    link: "/course/komentorivi/12",
  },
];

const part2 = [
  {
    title: "2.1",
    description: "pwd",
    link: "/course/komentorivi/21",
  },
  {
    title: "2.2",
    description: "ls",
    link: "/course/komentorivi/22",
  },
  {
    title: "2.3",
    description: "cd",
    link: "/course/komentorivi/23",
  },
  {
    title: "2.4",
    description: "clear",
    link: "/course/komentorivi/24",
  },
  {
    title: "2.5",
    description: "touch",
    link: "/course/komentorivi/25",
  },
  {
    title: "2.6",
    description: "mkdir",
    link: "/course/komentorivi/26",
  },
  {
    title: "2.7",
    description: "cat",
    link: "/course/komentorivi/27",
  },
  {
    title: "2.8",
    description: "history",
    link: "/course/komentorivi/28",
  },
  {
    title: "2.9",
    description: "cp",
    link: "/course/komentorivi/29",
  },
  {
    title: "2.10",
    description: "mv",
    link: "/course/komentorivi/210",
  },
  {
    title: "2.11",
    description: "rm",
    link: "/course/komentorivi/211",
  },
  {
    title: "2.12",
    description: "man",
    link: "/course/komentorivi/212",
  },
  {
    title: "2.13",
    description: "exit",
    link: "/course/komentorivi/213",
  },
  {
    title: "2.14",
    description: "wc",
    link: "/course/komentorivi/214",
  },
];

export default function Main() {
  const [courseDoneTasks, setCourseDoneTasks] = React.useState([]);
  const [courseProgress, setCourseProgress] = React.useState(0);
  const [showAlert, setShowAlert] = React.useState(false);

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const userId = firebase.auth().currentUser.uid;
        firebase
          .firestore()
          .collection("users")
          .doc(userId)
          .get()
          .then((doc) => {
            setCourseDoneTasks(doc.data().komentorivi);
            setCourseProgress(Object.keys(doc.data().komentorivi).length);
          })
          .catch(() => {});
      } else {
        setShowAlert(true);
      }
    });
  }, []);

  return (
    <main>
      <Link
        to="/"
        style={{
          marginTop: "15px",
          marginBottom: "15px",
          fontSize: "1.4em",
          color: "currentColor",
          textDecoration: "none",
        }}
      >
        ← Takaisin kursseihin
      </Link>
      {showAlert && (
        <Alert
          variant="filled"
          severity="error"
          style={{ backgroundColor: "#8379B0", color: "#fff" }}
          sx={{ mt: 1 }}
        >
          Kurssin edistyminen ei tallennu, jos et ole kirjautunut.
        </Alert>
      )}
      <h1 style={{ marginTop: "15px" }}>Komentorivi Tutuksi</h1>
      <Typography style={{ marginTop: "15px" }}>
        Opi käyttämään komentoriviä erilaisiin tehtäviin.
      </Typography>
      <h1 style={{ marginTop: "15px" }}>1. Komentorivin käyttö</h1>
      <Grid container spacing={4}>
        {part1.map((post) => (
          <CoursePart
            key={post.title}
            post={post}
            done={
              courseDoneTasks &&
              courseDoneTasks.hasOwnProperty(post.title.split(".").join(""))
            }
          />
        ))}
      </Grid>
      <h1 style={{ marginTop: "15px" }}>2. Komentorivin komennot</h1>
      <Grid container spacing={4}>
        {part2.map((post) => (
          <CoursePart
            key={post.title}
            post={post}
            done={
              courseDoneTasks &&
              courseDoneTasks.hasOwnProperty(post.title.split(".").join(""))
            }
          />
        ))}
      </Grid>
      <hr style={{ marginTop: "20px", marginBottom: "20px" }} />
      <Grid container justifyContent="center" sx={{ mb: 5 }}>
        <Grid item xs={12} md={12}>
          <Card sx={{ display: "flex", bgcolor: "#8379B0" }}>
            <CardContent sx={{ flex: 1 }}>
              <Grid container>
                <Typography component="h2" variant="h5" color="#fff">
                  Kurssin edistyminen (
                  {parseInt(
                    (courseProgress / Object.keys(Komentorivi).length) * 100
                  )}
                  %)
                </Typography>
                {(courseProgress / Object.keys(Komentorivi).length) * 100 >=
                  99 && (
                  <Button
                    variant="contained"
                    style={{
                      color: "#8379B0",
                      backgroundColor: "#fff",
                    }}
                    size="small"
                    sx={{ mx: 1 }}
                    onClick={() => CertificationDownload("Komentorivi Tutuksi")}
                  >
                    Todistus
                  </Button>
                )}
              </Grid>
              <LinearProgress
                variant="determinate"
                color="secondary"
                sx={{ my: 1 }}
                value={(courseProgress / Object.keys(Komentorivi).length) * 100}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </main>
  );
}
