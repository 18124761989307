import * as React from "react";
import Grid from "@mui/material/Grid";
import { Link, useHistory, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import marked from "marked";
import Python from "../lessons/Python.json";
import Verkkosivut from "../lessons/Verkkosivut.json";
import Komentorivi from "../lessons/Komentorivi.json";

export default function Main() {
  const { course, lesson } = useParams();

  const [alertType, setAlertType] = React.useState("");
  const [lessonData, setLessonData] = React.useState(null);

  React.useState(() => {
    if (course === "python") {
      setLessonData(Python[lesson]);
    } else if (course === "verkkosivut") {
      setLessonData(Verkkosivut[lesson]);
    } else if (course === "komentorivi") {
      setLessonData(Komentorivi[lesson]);
    }
  }, []);

  const [coursePartIndex, setCoursePartIndex] = React.useState(0);
  const [progress, setProgress] = React.useState(0);
  const [canProgress, setCanProgress] = React.useState(true);
  const [coursePartCheckedIndex, setCoursePartCheckedIndex] =
    React.useState(null);

  const history = useHistory();

  function getMarkdownText(text) {
    let rawMarkup = marked(text, { breaks: true });
    return { __html: rawMarkup };
  }

  const goNext = () => {
    setCoursePartCheckedIndex(null);
    if (
      lessonData[coursePartIndex + 1].options &&
      progress <= coursePartIndex + 1
    ) {
      setCanProgress(false);
    }
    if (progress === coursePartIndex) {
      setProgress(progress + 1);
    }
    setCoursePartIndex(coursePartIndex + 1);
    setAlertType("");
  };

  const goPrevious = () => {
    setCoursePartIndex(coursePartIndex - 1);
    setCanProgress(true);
    setAlertType("");
  };

  const submitAnswer = () => {
    const answer = parseInt(lessonData[coursePartIndex].answer);
    if (answer === coursePartCheckedIndex + 1) {
      setCanProgress(true);
      setAlertType("success");
    } else {
      setAlertType("error");
    }
  };

  const finish = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const lessons = { [lesson]: new Date() };
        const userId = firebase.auth().currentUser.uid;
        firebase
          .firestore()
          .collection("users")
          .doc(userId)
          .set(
            {
              [course]: lessons,
            },
            { merge: true }
          )
          .then(() => {
            history.push(`/course/${course}`);
          })
          .catch(() => {
            history.push(`/course/${course}`);
          });
      } else {
        history.push(`/course/${course}`);
      }
    });
  };

  return (
    <main>
      <Link
        to={`/course/${course}`}
        style={{
          marginTop: "15px",
          marginBottom: "15px",
          fontSize: "1.4em",
          color: "currentColor",
          textDecoration: "none",
        }}
      >
        ← Takaisin kurssiin
      </Link>
      {lessonData && (
        <div>
          <h1 style={{ marginTop: "15px" }}>{`${lesson[0]}.${lesson.substring(
            1
          )}`}</h1>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={8}>
              <div
                className="markdown"
                dangerouslySetInnerHTML={getMarkdownText(
                  lessonData[coursePartIndex].text
                )}
                target="_blank"
              />
              {lessonData[coursePartIndex].options && (
                <div>
                  <Stack spacing={2} sx={{ mx: 4, my: 1 }}>
                    <Button
                      variant={
                        coursePartCheckedIndex === 0 ? "contained" : "outlined"
                      }
                      style={{
                        backgroundColor:
                          coursePartCheckedIndex === 0
                            ? "#8379B0"
                            : "#00000000",
                        color: "#fff",
                        borderColor: "#8379B0",
                        textTransform: "none",
                      }}
                      onClick={() => setCoursePartCheckedIndex(0)}
                    >
                      {lessonData[coursePartIndex].options[0]}
                    </Button>
                    <Button
                      variant={
                        coursePartCheckedIndex === 1 ? "contained" : "outlined"
                      }
                      style={{
                        backgroundColor:
                          coursePartCheckedIndex === 1
                            ? "#8379B0"
                            : "#00000000",
                        color: "#fff",
                        borderColor: "#8379B0",
                        textTransform: "none",
                      }}
                      onClick={() => setCoursePartCheckedIndex(1)}
                    >
                      {lessonData[coursePartIndex].options[1]}
                    </Button>
                    <Button
                      variant={
                        coursePartCheckedIndex === 2 ? "contained" : "outlined"
                      }
                      style={{
                        backgroundColor:
                          coursePartCheckedIndex === 2
                            ? "#8379B0"
                            : "#00000000",
                        color: "#fff",
                        borderColor: "#8379B0",
                        textTransform: "none",
                      }}
                      onClick={() => setCoursePartCheckedIndex(2)}
                    >
                      {lessonData[coursePartIndex].options[2]}
                    </Button>
                    <Button
                      variant={
                        coursePartCheckedIndex === 3 ? "contained" : "outlined"
                      }
                      style={{
                        backgroundColor:
                          coursePartCheckedIndex === 3
                            ? "#8379B0"
                            : "#00000000",
                        color: "#fff",
                        borderColor: "#8379B0",
                        textTransform: "none",
                      }}
                      onClick={() => setCoursePartCheckedIndex(3)}
                    >
                      {lessonData[coursePartIndex].options[3]}
                    </Button>
                  </Stack>
                  {alertType === "success" ? (
                    <Alert
                      variant="filled"
                      severity="success"
                      style={{ backgroundColor: "#79b07c", color: "#fff" }}
                      sx={{ mt: 1, mx: 4 }}
                    >
                      Oikein!
                    </Alert>
                  ) : (
                    alertType === "error" && (
                      <Alert
                        variant="filled"
                        severity="error"
                        style={{ backgroundColor: "#b07979", color: "#fff" }}
                        sx={{ mt: 1, mx: 4 }}
                      >
                        Väärin!
                      </Alert>
                    )
                  )}
                </div>
              )}
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              style={{ backgroundColor: "#8379B0" }}
              sx={{ my: 5, mx: 1, width: 180, height: 50 }}
              onClick={() => goPrevious()}
              disabled={coursePartIndex < 1}
            >
              Edellinen
            </Button>
            {canProgress ? (
              <Button
                variant="contained"
                style={{ backgroundColor: "#8379B0" }}
                sx={{ my: 5, mx: 1, width: 180, height: 50 }}
                onClick={() => {
                  coursePartIndex >= lessonData.length - 1
                    ? finish()
                    : goNext();
                }}
              >
                {coursePartIndex >= lessonData.length - 1
                  ? "Valmis"
                  : "Seuraava"}
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{ backgroundColor: "#8379B0" }}
                sx={{ my: 5, mx: 1, width: 180, height: 50 }}
                onClick={() => submitAnswer()}
                disabled={coursePartCheckedIndex === null}
              >
                Tarkista
              </Button>
            )}
          </Box>
        </div>
      )}
    </main>
  );
}
