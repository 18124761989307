import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Alert from "@mui/material/Alert";

import { Link, useHistory } from "react-router-dom";

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const theme = createTheme({
  palette: {
    background: {
      default: "#35385F",
    },
    primary: {
      main: "#fff",
    },
  },
});

export default function SignIn(props) {
  const [alertMessage, setAlertMessage] = React.useState();

  const history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setAlertMessage("");
        const user = userCredential.user;
        props.setUser(user);
        history.push("/");
      })
      .catch((error) => {
        setAlertMessage("Sähköposti tai salasana on väärin.");
        console.log(error);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#8379B0" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" style={{ color: "#fff" }}>
            Kirjaudu
          </Typography>
          {alertMessage && (
            <Alert
              variant="filled"
              severity="error"
              style={{ backgroundColor: "#8379B0", color: "#fff" }}
              sx={{ mt: 1 }}
            >
              {alertMessage}
            </Alert>
          )}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Sähköposti"
              name="email"
              autoComplete="email"
              autoFocus
              sx={{ input: { color: '#fff' } }}
              InputLabelProps={{
                style: { color: "#fff" },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Salasana"
              type="password"
              id="password"
              autoComplete="current-password"
              sx={{ input: { color: '#fff' } }}
              InputLabelProps={{
                style: { color: "#fff" },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{ backgroundColor: "#8379B0", color: "#fff" }}
              sx={{ mt: 3, mb: 2 }}
            >
              Kirjaudu
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  to="/forgot-password"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                  }}
                >
                  Unohditko salasanasi?
                </Link>
              </Grid>
              <Grid item>
                <Link
                  to="signup"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                  }}
                >
                  {"Luo käyttäjätili"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
