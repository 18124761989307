import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import Container from "@mui/material/Container";
import Header from "./components/Header";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import Main from "./pages/Main";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import ForgotPassword from "./pages/ForgotPassword";
import ProfilePage from "./pages/ProfilePage";
import NoMatch from "./pages/NoMatch";

import PythonCourse from "./pages/courses/Python";
import VerkkosivutCourse from "./pages/courses/Verkkosivut";
import KomentoriviCourse from "./pages/courses/Komentorivi";

import CourseLesson from "./pages/CourseLesson";

firebase.initializeApp({
  apiKey: "AIzaSyC-qrwJzrVAWac6CFVCkHLgYiHXoxv1Vtg",
  authDomain: "jemedev-sumi.firebaseapp.com",
  projectId: "jemedev-sumi",
  storageBucket: "jemedev-sumi.appspot.com",
  messagingSenderId: "527795754232",
  appId: "1:527795754232:web:eb5befb794e72ced8f570d",
});

const themeDark = createTheme({
  palette: {
    background: {
      default: "#35385F",
    },
    primary: {
      main: "#8379B0",
      secondary: "#8379B0",
    },
    text: {
      primary: "#ffffff",
    },
  },
});

const App = () => {
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, []);

  return (
    <MuiThemeProvider theme={themeDark}>
      <CssBaseline />

      <Router>
        <Container maxWidth="lg">
          <Header user={user} />
          <Switch>
            <Route exact path="/">
              <Main />
            </Route>
            <Route exact path="/signup">
              {!user ? <SignUp setUser={setUser} /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/signin">
              {!user ? <SignIn setUser={setUser} /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/forgot-password">
              {!user ? <ForgotPassword /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/profile">
              <ProfilePage />
            </Route>
            <Route exact path="/course/python">
              <PythonCourse />
            </Route>
            <Route exact path="/course/verkkosivut">
              <VerkkosivutCourse />
            </Route>
            <Route exact path="/course/komentorivi">
              <KomentoriviCourse />
            </Route>
            <Route exact path="/course/:course/:lesson">
              <CourseLesson />
            </Route>
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </Container>
      </Router>
    </MuiThemeProvider>
  );
};

export default App;
