import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const theme = createTheme({
  palette: {
    background: {
      default: "#35385F",
    },
    primary: {
      main: "#fff",
    },
  },
});

export default function ForgotPassword() {
  const [alertType, setAlertType] = React.useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const auth = getAuth();
    sendPasswordResetEmail(auth, data.get("email"))
      .then((success) => {
        setAlertType("success");
      })
      .catch((error) => {
        setAlertType("error");
        console.log(error);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#8379B0" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" style={{ color: "#fff" }}>
            Palauta Salasana
          </Typography>
          {alertType === "success" ? (
            <Alert variant="filled" severity="success" sx={{ mt: 1 }}>
              Onnistui! Katso sähköpostisi.
            </Alert>
          ) : (
            alertType === "error" && (
              <Alert
                variant="filled"
                severity="error"
                style={{ backgroundColor: "#8379B0", color: "#fff" }}
                sx={{ mt: 1 }}
              >
                Käyttäjää ei ole olemassa kyseisellä sähköpostilla.
              </Alert>
            )
          )}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Sähköposti"
              name="email"
              autoComplete="email"
              autoFocus
              sx={{ input: { color: '#fff' } }}
              InputLabelProps={{
                style: { color: "#fff" },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{ backgroundColor: "#8379B0", color: "#fff" }}
              sx={{ mt: 3, mb: 2 }}
            >
              Palauta
            </Button>
            <Grid container>
              <Grid item>
                <Link
                  to="/signin"
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                  }}
                >
                  Muistatko salasanasi?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
