import * as React from "react";

import { Link } from "react-router-dom";

const NoMatch = () => {
  return (
    <main>
      <h1 align="center" style={{ margin: "30px", fontSize: "3em" }}>
        Hups, bitit taisivat mennä sekaisin :(
      </h1>
      <Link to="/" style={{ color: "#fff", fontSize: "1.9em" }}>
        Takaisin etusivulle
      </Link>
    </main>
  );
};

export default NoMatch;
