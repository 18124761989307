import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        mt: 3,
        py: 4,
      }}
    >
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="center">
          <Button
            href="mailto:eemelimark@gmail.com"
            variant="contained"
            style={{ backgroundColor: "#8379B0" }}
            sx={{ mx: 1, width: 190, height: 50 }}
          >
            Ota yhteyttä
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
