import * as React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Link, useHistory } from "react-router-dom";
import UserProgress from "../components/UserProgress";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export default function ProfilePage() {
  const [name, setName] = React.useState("");
  const history = useHistory();
  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const userId = firebase.auth().currentUser.uid;
        firebase
          .firestore()
          .collection("users")
          .doc(userId)
          .get()
          .then((doc) => {
            setName(doc.data().name);
          });
      } else {
        history.push("/signin");
      }
    });
  }, [history]);

  const saveNameToFirebase = (name) => {
    setName(name);
    const userId = firebase.auth().currentUser.uid;
    firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .set(
        {
          name: name,
        },
        { merge: true }
      )
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <main>
      <Paper variant="outlined" sx={{ p: { xs: 2 } }}>
        <TextField
          fullWidth
          id="name"
          label="Nimi"
          name="name"
          autoComplete="name"
          InputLabelProps={{
            style: { color: "#000" },
          }}
          value={name}
          onChange={(event) => saveNameToFirebase(event.target.value)}
        />
      </Paper>

      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Typography component="h1" variant="h4" align="center">
          Edistyminen
        </Typography>
        <UserProgress />
      </Paper>
      <Link
        to="/"
        style={{
          textDecoration: "none",
        }}
      >
        <Button variant="contained" style={{ backgroundColor: "#8379B0" }}>
          Takaisin etusivulle
        </Button>
      </Link>
    </main>
  );
}
