import * as React from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import CardContent from "@mui/material/CardContent";
import CoursePart from "../../components/CoursePart";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import CertificationDownload from "../../CertificationDownload";

import Verkkosivut from "../../lessons/Verkkosivut.json";

const part1 = [
  {
    title: "1.1",
    description: "HTML alkeet",
    link: "/course/verkkosivut/11",
  },
  {
    title: "1.2",
    description: "HTML rakenne",
    link: "/course/verkkosivut/12",
  },
  {
    title: "1.3",
    description: "Teksti",
    link: "/course/verkkosivut/13",
  },
  {
    title: "1.4",
    description: "Linkit",
    link: "/course/verkkosivut/14",
  },
  {
    title: "1.5",
    description: "Kuvat",
    link: "/course/verkkosivut/15",
  },
  {
    title: "1.6",
    description: "Listat",
    link: "/course/verkkosivut/16",
  },
  {
    title: "1.7",
    description: "Taulukot",
    link: "/course/verkkosivut/17",
  },
  {
    title: "1.8",
    description: "Lomakkeet",
    link: "/course/verkkosivut/18",
  },
];

const part2 = [
  {
    title: "2.1",
    description: "CSS alkeet",
    link: "/course/verkkosivut/21",
  },
  {
    title: "2.2",
    description: "CSS rakenne",
    link: "/course/verkkosivut/22",
  },
  {
    title: "2.3",
    description: "Lisää tyyleistä",
    link: "/course/verkkosivut/23",
  },
  {
    title: "2.4",
    description: "Yleisimmät ominaisuudet",
    link: "/course/verkkosivut/24",
  },
];

const part3 = [
  {
    title: "3.1",
    description: "JavaScript alkeet",
    link: "/course/verkkosivut/31",
  },
  {
    title: "3.2",
    description: "JavaScriptin rakenne",
    link: "/course/verkkosivut/32",
  },
  {
    title: "3.3",
    description: "Funktiot",
    link: "/course/verkkosivut/33",
  },
  {
    title: "3.4",
    description: "Lisää ominaisuuksia",
    link: "/course/verkkosivut/34",
  },
];

export default function Main() {
  const [courseDoneTasks, setCourseDoneTasks] = React.useState([]);
  const [courseProgress, setCourseProgress] = React.useState(0);
  const [showAlert, setShowAlert] = React.useState(false);

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const userId = firebase.auth().currentUser.uid;
        firebase
          .firestore()
          .collection("users")
          .doc(userId)
          .get()
          .then((doc) => {
            setCourseDoneTasks(doc.data().verkkosivut);
            setCourseProgress(Object.keys(doc.data().verkkosivut).length);
          })
          .catch(() => {});
      } else {
        setShowAlert(true);
      }
    });
  }, []);

  return (
    <main>
      <Link
        to="/"
        style={{
          marginTop: "15px",
          marginBottom: "15px",
          fontSize: "1.4em",
          color: "currentColor",
          textDecoration: "none",
        }}
      >
        ← Takaisin kursseihin
      </Link>
      {showAlert && (
        <Alert
          variant="filled"
          severity="error"
          style={{ backgroundColor: "#8379B0", color: "#fff" }}
          sx={{ mt: 1 }}
        >
          Kurssin edistyminen ei tallennu, jos et ole kirjautunut.
        </Alert>
      )}
      <h1 style={{ marginTop: "15px" }}>Verkkosivujen Kehitys</h1>
      <Typography style={{ marginTop: "15px" }}>
        Opi suunnittelemaan ja rakentamaan verkkosivuja käyttämällä HTML, CSS ja
        JavaScriptiä.
      </Typography>
      <h1 style={{ marginTop: "15px" }}>1. HTML</h1>
      <Grid container spacing={4}>
        {part1.map((post) => (
          <CoursePart
            key={post.title}
            post={post}
            done={
              courseDoneTasks &&
              courseDoneTasks.hasOwnProperty(post.title.split(".").join(""))
            }
          />
        ))}
      </Grid>
      <h1 style={{ marginTop: "15px" }}>2. CSS</h1>
      <Grid container spacing={4}>
        {part2.map((post) => (
          <CoursePart
            key={post.title}
            post={post}
            done={
              courseDoneTasks &&
              courseDoneTasks.hasOwnProperty(post.title.split(".").join(""))
            }
          />
        ))}
      </Grid>
      <h1 style={{ marginTop: "15px" }}>3. JavaScript</h1>
      <Grid container spacing={4}>
        {part3.map((post) => (
          <CoursePart
            key={post.title}
            post={post}
            done={
              courseDoneTasks &&
              courseDoneTasks.hasOwnProperty(post.title.split(".").join(""))
            }
          />
        ))}
      </Grid>
      <hr style={{ marginTop: "20px", marginBottom: "20px" }} />
      <Grid container justifyContent="center" sx={{ mb: 5 }}>
        <Grid item xs={12} md={12}>
          <Card sx={{ display: "flex", bgcolor: "#8379B0" }}>
            <CardContent sx={{ flex: 1 }}>
              <Grid container>
                <Typography component="h2" variant="h5" color="#fff">
                  Kurssin edistyminen (
                  {parseInt(
                    (courseProgress / Object.keys(Verkkosivut).length) * 100
                  )}
                  %)
                </Typography>
                {(courseProgress / Object.keys(Verkkosivut).length) * 100 >=
                  99 && (
                  <Button
                    variant="contained"
                    style={{
                      color: "#8379B0",
                      backgroundColor: "#fff",
                    }}
                    size="small"
                    sx={{ mx: 1 }}
                    onClick={() =>
                      CertificationDownload("Verkkosivujen kehitys")
                    }
                  >
                    Todistus
                  </Button>
                )}
              </Grid>
              <LinearProgress
                variant="determinate"
                color="secondary"
                sx={{ my: 1 }}
                value={(courseProgress / Object.keys(Verkkosivut).length) * 100}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </main>
  );
}
